import i18n from "@/client/i18n";
import moment from "moment";
import { format } from "date-fns";
import { ja } from "date-fns/locale";
const { t } = i18n.global;
export const isCheckValidate = (data, error) => {
  Object.keys(data).forEach((item) => {
    if (!data[item]) {
      error[item] = t(`booking_tour_space.require`);
    } else {
      error[item] = null;
    }
  });
};
export const convertDateOfBirth = (date) => {
  if (date) {
    if (String(date).length === 8) {
      const year = String(date).substr(0, 4);
      const month = String(date).substr(4, 2);
      const day = String(date).substr(6, 2);
      const newDate = new Date(`${year}-${month}-${day}`);
      const newDate2 = format(newDate, "yyyy年M月dd日  (E)", {
        locale: ja,
      });
      return newDate2;
    } else {
      // let result = "";
      // const year = String(date).substr(0, 4);
      // const month = String(date).substr(4, 2);
      // const day = String(date).substr(6, 2);
      // result = `${year}年${month}月${day}日`;
      const newDate = new Date(moment.unix(date).format("YYYY-MM-DD"));
      const newDate2 = format(newDate, "yyyy年M月dd日  (E)", {
        locale: ja,
      });
      return newDate2;
    }
  }
};
export const convertDateOfBirth1 = (date) => {
  if (date) {
    const year = String(date).substr(0, 4);
    const month = String(date).substr(4, 2);
    const day = String(date).substr(6, 2);
    const newDate = new Date(`${year}-${month}-${day}`);
    const newDate2 = format(newDate, "yyyy年MM月dd日  (E)", {
      locale: ja,
    });
    return newDate2;
  }
};
export const convertDate = (date) => {
  if (date) {
    const newDate = new Date(moment(date).format("YYYY-MM-DD"));
    const newTime = moment(date).format("HH:mm");

    const newDate2 = format(newDate, "yyyy年M月dd日  (E)", {
      locale: ja,
    });
    return `${newDate2} ${newTime}`;
  }

  // console.log("test", test2);
  // console.log(
  //   format(test, "yyyy年MM月dd  (E)", {
  //     locale: ja,
  //   })
  // );
  // return date ? moment(date).format("YYYY年M月D日 HH時mm分") : "";
};

export const convertLimitDate = (reservationCreatedDay, dayOfUse) => {
  if (!(moment.isMoment(reservationCreatedDay) && moment.isMoment(dayOfUse))) {
    throw new Error('The parameters reservationCreatedDay and dayOfUse should be instances of moment.');
  }

  const deadline = reservationCreatedDay.add(7, 'day');
  // The deadline of payment will be set 7 days later of the reservation record created
  //  if the day of use the space is 8 or more days later than the reservation record created.
  // Otherwise the deadline will be set the day before day of use the space.
  if (deadline.isBefore(dayOfUse)) {
    return format(deadline.toDate(), "yyyy年M月dd日 (E)", { locale: ja });
  } else {
    return format(dayOfUse.subtract(2, 'day').toDate(), "yyyy年M月dd日 (E)", { locale: ja })
  }
}

export const convertImgPathWithEnv = (pathImage) => {
  let result = ''
  const baseURL = process.env.VUE_APP_API_URL;
  if (pathImage && baseURL) {
    result = baseURL + pathImage;
  }
  return result
}

// CloudFlare Image Resizing & image format chenge
// In the dvelopment env, not resizing
//
// @params pathImage String
// @params quality String (1～99)
// return String ex) "/cdn-cgi/image/format=webp,quality/image.png"
export const ImageResizing = (pathImage, quality) => {
  if (
    process.env.VUE_APP_MODE === "production" ||
    process.env.VUE_APP_MODE === "staging" ||
    process.env.VUE_APP_MODE === "develop"
  ) {
    return "/cdn-cgi/image/format=webp,quality=" + quality + pathImage;
  } else {
    console.log("ImageResizing : " + process.env.VUE_APP_MODE);
    return pathImage;
  }
}

// change canonical tag
//
// @params urlPath String
export const ChangeCanonicalTag = (urlPath) => {
  document.querySelector("link[rel='canonical']").setAttribute('href', urlPath);
}